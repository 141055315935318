const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://localhost:5001/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
  },
  production: {
      api: "https://thuyloihaiduong-api.cgis.asia/",
      domainAdminSide: "https://admin.thuyloihaiduong.cgis.asia",
      domainUserSide: "https://thuyloihaiduong.cgis.asia",
      domainName: "thuyloihaiduong.cgis.asia",
  },
};

module.exports = apiEnvironment[env];
